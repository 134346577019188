.content_blocks {

	background-color: white;

	.row {
		
		padding-top: 20px;
		padding-bottom: 20px;

		@media only screen and ($small-desktop) {
			padding-left: 50px;
			padding-right: 50px;
			padding-top: 40px;
			padding-bottom: 40px;
		}

		&.single_column {

			margin-top: 20px;
			margin-bottom: 20px;

			@media only screen and ($small-desktop) {

				margin-top: 40px;
				margin-bottom: 40px;

				&:first-of-type {
					margin-top: 0;
				}

				&.content_block_blue {
					margin-top: 90px;
				}
			}

			h2 {

				font-family: $primaryFont;
				font-weight: 600;
				text-align: center;
				font-size: $baseFontSize * 2.2;
			}

			p, ul {

				font-family: $primaryFont;
				font-weight: 400;
				color: $tertiaryColor;
				font-size: $baseFontSize;

				a {

					color: $fourthColor;
					font-weight: 700;
				}
			}

			.bb_button {

				display: block;
				background-color: $secondaryColor;

				&:hover {

					background-color: darken($secondaryColor, 10%);
				}

				@media only screen and ($small-desktop) {

					width: 200px;
				}
			}
		}

		&.two_column {


			h2 {

				font-family: $primaryFont;
				font-weight: 600;
				color: $tertiaryColor;
				font-size: $baseFontSize * 1.6;
				margin-bottom: 30px;
			}

			p, ul {

				font-family: $primaryFont;
				font-weight: 400;
				color: $tertiaryColor;
				font-size: $baseFontSize;

				a {

					color: $fourthColor;
					font-weight: 700;
				}
			}

			.bb_button {

				display: block;
				background-color: $secondaryColor;

				&:hover {

					background-color: darken($secondaryColor, 10%);
				}

				@media only screen and ($device-max) {

					margin-top: 40px;
				}

				@media only screen and ($small-desktop) {

					width: 200px;
				}
			}

			.column_image {

				@media only screen and ($device-max) {

					margin-top: 20px;
					margin-bottom: 20px;
				}
			}
		}

		&.content_block_blue {

			background-color: $secondaryColor;
			position: relative;

			p {

				color: white;
				text-align: center;
			}

			h2 {

				color: $white;
			}

			ul {
				li {
					color: white;

				}
			}

			.bb_button {

				
				display: block;
				background-color: $primaryColor;

				&:hover {

					background-color: darken($primaryColor, 10%);
				}

				@media only screen and ($small-desktop) {

					margin: 0 auto;
					width: 200px;
				}
			}

			@media only screen and ($small-desktop) {

				&:before {

					content: '';
					width: 100%;
					height: 100px;
					background-image: url(#{$imagePath}components/content-block-single-top.png);
					position: absolute;
					top: -99px;
					left: 0;
					background-repeat: no-repeat;
				}

				&:after {

					content: '';
					width: 150px;
					height: 87px;
					background-image: url(#{$imagePath}components/content-block-single-bottom.png);
					position: absolute;
					bottom: -87px;
					right: 0;
					background-repeat: no-repeat;
				}
			}
		} 
	}
}