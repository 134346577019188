.bb_modal {

	h2 {

		font-family: $primaryFont;
		font-weight: 700;
		text-transform: uppercase;
		font-size: $baseFontSize * 1.6;
	}

	p {

		font-family: $primaryFont;
		font-weight: 400;
		font-size: $baseFontSize;
		line-height: $baseFontSize * 1.8;
		color: $tertiaryColor;

		a {

			text-decoration: underline;
			color: $fourthColor;
		}
	}
}