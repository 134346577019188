.pre_nav {

	background-color: $secondaryColor;


	@media only screen and ($small-desktop) {

		padding: 15px 10px 15px 195px;
	}

	.header_contact {

		padding: 10px 0;
		margin: 0;

		@media only screen and ($small-desktop) {

			padding: 0;
		}

		li {
			
			color: white;
			font-family: $primaryFont;
			font-weight: 500;
			font-size: $baseFontSize * 0.9;
			display: inline;
			list-style: none;
			list-style-type: none;

			&:not(:last-child) {

				margin-right: 10px;

				@media only screen and ($small-desktop) {

					margin-right: 20px;
				}
			}

			a {
				color: white;
			}
		}
	}

	.social_links {

		float: right;
		margin-bottom: 0;
		white-space:nowrap;

		li {

			list-style: none;
			list-style-type: none;
			display: inline-block;

			&:not(:last-child) {

				margin-right: 20px;
			}
		}
	}
}

.banner_wrapper {

	background-color: white;
	position: relative;
	padding: 20px 0 0;

	.navbar {
		z-index: 20;
	}

	@media only screen and ($device-max) {

		.navbar {
			padding-bottom: 20px;
		}
	}

	@media only screen and ($small-desktop) {

		&:before {

			width: 230px;
			height: 120px;
			content:'';
			background-image: url('#{$imagePath}components/banner-graphic.png');
			background-size: 100%;
			background-position: top left;
			background-repeat: no-repeat;
			position: absolute;
			top: -55px;
			left: 0;
		}

		.dropdown:hover>.dropdown-menu {
		  display: block;
		}
	}

	.bb_strapline {

		font-family: $primaryFont;
		color: $primaryColor;
		font-size: $baseFontSize * 1.25;
		font-weight: 200;

		span {

			font-weight: 500;
			margin-left: 15px;
		}

	}

	.main_navigation {

		border-top: 3px solid $secondaryColor;
		margin-top: 10px;

		@media only screen and ($small-desktop) {
			border: none;
		}

		ul {

			li {

				a {

					@media only screen and ($device-max) {

						padding: 15px 0;
					}

					color: $primaryColor;
					font-family: $primaryFont;
					font-weight: 200;
					font-size: $baseFontSize * 0.8;
					text-transform: uppercase;

					@media only screen and ($small-desktop) {

						font-size: $baseFontSize * 0.8;
					}

					@media only screen and ($large-desktop) {

						font-size: $baseFontSize * 0.8;
					}
				}

				&.dropdown {

					.show-menu {

						@media only screen and ($device-max) {

							float: right;
							background-color: $bbLightGrey;
							width: 40px;
							text-align: center;
							display: inline-block;
						}
					}
				}

				.dropdown-menu {

					background-color: white;

					.dropdown-item {
						&:active {
							background-color: $secondaryColor;
						}
					}


					@media only screen and ($device-max) {

						padding-left: 30px;
					}
					
					@media only screen and ($small-desktop) {

						border-top: 3px solid $secondaryColor;
					}
				}
			}
		}

		>ul {

			li {

				&.active {

					>a {

						color: $fourthColor;
						font-weight: 600;
					}
				}

			}
		}
	}

	.banner_buttons {

		a {

			width: 50%;
			display: inline-block;

			&:first-child {

				float: left;
				width: 49%;
				margin-right: 1%;
			}
		}
	}

	.banner_site_search {

		margin-top: 10px;
		position: relative;

		form {

			&:before {

				@include verticalCenter;
				content: '';
				width: 18px;
				height: 18px;
				background-image: url('#{$imagePath}components/search-icon.svg');
				background-repeat: no-repeat;
				background-position: center center;
				left: 10px;
			}

			input {

				padding-left: 35px;
			}
		}
	}
}