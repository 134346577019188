.job_logo {

	@media only screen and ($small-desktop) {

		max-width: 180px;
	}
}

.job_body{

	img {
		max-width: 100%;
	}
}