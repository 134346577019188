.member_form {

	padding: 60px 0;

	form {

		label {

			font-family: $primaryFont;
			font-weight: 400;
			color: $primaryColor;
		}

		.bb_button {

			border: 0;
			width: 200px;
			margin: 0 auto;
			display: block;
		}
	}

	p {

		text-align: center;

		a {

			color: $fourthColor;
			font-family: $primaryFont;
			font-weight: 400;
			display: block;
			margin-top: 20px;
		}
	}

	&.member_profile_form {

		background-color: $bbLightGrey;
		padding: 20px;

		.row {

			margin-bottom: 20px;
		}
	}
}