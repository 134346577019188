/**
 *
 * Add any base styles here.
 *
 */

@-ms-viewport{
  width: device-width;
}

body {

	background-color: lighten($primaryColor, 60%);

	.content_wrapper {

		background-color: white;
		padding-bottom: 40px;

		padding-left: 20px;
		padding-right: 20px;

		&_row {
			padding-left: 10px;
			padding-right: 10px;
			padding-bottom: 20px;
		}

	

		@media only screen and ($small-desktop) {

			padding-left: 40px;
			padding-right: 40px;
	
			&_row {
				padding-left: 30px;
				padding-right: 30px;
			}

			padding-bottom: 60px;
		}



		h1 {

			font-family: $primaryFont;
			font-weight: 700;
			font-size: $baseFontSize * 1.8;
			color: $primaryColor;
			margin: 40px 0 10px;
			text-transform: uppercase;

			@media only screen and ($small-desktop) {

				font-size: $baseFontSize * 2.0;
			}
		}

		h2 {

			font-family: $primaryFont;
			font-weight: 700;
			font-size: $baseFontSize * 1.4;
			color: $tertiaryColor;
			margin: 20px 0;
			text-transform: uppercase;

			@media only screen and ($small-desktop) {

				font-size: $baseFontSize * 1.8;
			}

			&.subtitle {

				font-weight: 400;
				text-transform: none;
			}

			@media only screen and ($small-desktop) {

				font-size: $baseFontSize * 1.2;
			}
		}


		h5 {

			font-family: $primaryFont;
			font-weight: 700;
			font-size: $baseFontSize * 0.9;
			color: $fourthColor;

			@media only screen and ($small-desktop) {

				font-size: $baseFontSize * 1.2;
			}
		}

		.back_link {

			font-family: $primaryFont;
			font-weight: 500;
			font-size: $baseFontSize * 0.75;
			color: $primaryColor;
			display: block;
			padding-top: 15px;

			&:hover {

				text-decoration: none;
				color: $fourthColor;
			}
		}

		p, ul {

			font-family: $primaryFont;
			font-weight: 400;
			font-size: $baseFontSize;
			line-height: $baseFontSize * 1.8;
			color: $tertiaryColor;

			a {

				color: $fourthColor;
				font-weight: 700;
			}
		}

		ul {
			padding-inline-start: 20px;
			li {
				font-weight: 400;
				color: $tertiaryColor;
				a {

					font-weight: 500;
				}
			}
		}
	}
}