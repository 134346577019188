.pre_footer {

	background-color: $primaryColor;
	position: relative;
	padding: 20px;
	margin-top:40px;

	@media screen and ($device-max) {

		.pre_footer_box {

			&:not(:last-child) {

				margin-bottom: 20px;
			}
		}
	}

	&:before {

		content: '';
		background-color: white;
		background-image: url('#{$imagePath}components/pre-footer-edge.png');
		background-position: center center;
		background-repeat: no-repeat;
		width: 100%;
		height: 40px;
		position: absolute;
		top: -40px;
		left: 0;
	}

	h4 {

		font-family: $primaryFont;
		color: white;
		text-align: center;
		font-size: $baseFontSize * 1.4;
		text-transform: uppercase;
		font-weight: 600;
		padding-bottom: 20px;
		margin-bottom: 30px;
		border-bottom: 1px solid white;
	}

	form {

		.row {

			padding: 0 20px;
		}

		.form-check {

			margin-top: 10px;
			margin-left: -5px;

			label {

				margin-left: 10px;
			}
		}

		input {

			&[type="text"], &[type="email"] {

				border: 0;
				background-color: white;
				height: 35px;
				color: $primaryColor;
				font-family: $primaryFont;
				font-size: $baseFontSize * 0.7;
				width: 100%;
				display: block;
				margin-bottom: 10px;
				border-radius: 0;
				padding-left: 15px;
			}

			&[type="checkbox"] {

				border: 0;
			}

			&[type="submit"] {

				display: block;
				width: 100%;
				border: 0;
				margin-top: 10px;
				padding: 10px 15px;
				border-radius: 0;
			}
		}

		label, p {

			color: white;
			font-family: $primaryFont;
			font-size: $baseFontSize * 0.7;
		}
	}

	.legal_text {

		color: white;
		font-size: $baseFontSize;

		a {

			color: $fourthColor;
			text-decoration: underline;
		}
	}

	.course_box {

		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid white;

		&:last-child {

			border: 0;
		}

		h5 {

			color: white;
			font-family: $primaryFont;
			font-size: $baseFontSize * 0.9;
			text-transform: uppercase;
			font-weight: 600;
		}

		p {

			color: white;
			font-family: $primaryFont;
			font-size: $baseFontSize * 0.8;
			margin: 0;

			&:first-of-type {

				margin-bottom: 10px;
			}

			span {

				font-weight: 700;
			}

			&.next_session_date {

				float: right;
			}
		}

		a {
			color: white;
			font-family: $primaryFont;
			font-size: $baseFontSize * 0.8;
			font-weight: 700;
			float: left;

		}
	}

	.tweets_wrapper {

		padding-left: 0;

		li {

			color: white;
			font-family: $primaryFont;
			font-size: $baseFontSize * 0.8;
			list-style: none;
			list-style-type: none;

			&:not(:last-child) {

				margin-bottom: 15px;
				padding-bottom: 15px;
				border-bottom: 1px solid white;
			}

			a {

				color: $fourthColor;
				font-weight: 700;
				text-decoration: none;

				&:hover {

					text-decoration: none;
					color: white;
				}
			}
		}
	}
}