.blog_sidebar_wrapper {

	background-color: $primaryColor;
	padding: 20px;
	margin: 80px 0 50px;
	position: relative;

	@media only screen and ($small-desktop) {

		&:before {

			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 197.5px 50px 0;
			border-color: transparent transparent $primaryColor transparent;
			content: "";
			position: absolute;
			top: -50px;
			left: 0;
		}

		&:after {

			width: 0;
			height: 0;
			border-style: solid;
			border-width: 50px 197.5px 0 0px;
			border-color: $primaryColor transparent transparent transparent;
			content: "";
			position: absolute;
			bottom: -50px;
			left: 0;
		}
	}

	@media only screen and ($large-desktop) {

		&:before {

			border-width: 0 0 50px 243px;
		}

		&:after {

			border-width: 50px 0 0 243px;
		}
	}

	h3 {

		color: white;
		font-family: $primaryFont;
		font-weight: 600;
		text-transform: uppercase;
		font-size: $baseFontSize * 1.4;
		margin-bottom: 30px;
	}

	.sidebar_article {

		font-family: $primaryFont;
		font-weight: 400;
		border-bottom: 1px solid white;
		margin-bottom: 10px;

		&:last-child {

			border: none !important; 
		}

		a {

			@include multiTransition;
			color: $secondaryColor;
			font-weight: 700;
			font-size: $baseFontSize;

			&:hover {

				color: white;
				text-decoration: none;
			}
		}

		p {

			color: white;
			font-size: $baseFontSize * 0.75;

			span {

				font-weight: 700;
			}
		}
	}
}