.hero_image {
  background-repeat: no-repeat;
  background-position: center center;
  background-color: $primaryColor;

  &__controls {
    z-index: 10;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 10px;
    bottom: 10px;

    div {
      width: 30px;
      height: 30px;
      border-radius: 3px;
      position: absolute;
      top: 0;
      cursor: pointer;
      background-color: #fff;

      svg {
        margin: 5px;
      }
    }
  }

  &--video {
    overflow: hidden;
    position: relative;

    video {
      width: 100%;
      display: block;
    }
  }

  img {
    width: 100%;
  }

}
