.bb_button {

	@include multiTransition;
	font-family: $primaryFont;
	color: white;
	font-weight: 700;
	font-size: $baseFontSize * 0.9;
	text-align: center;
	padding: 15px 0;
	text-transform: uppercase;
	border-radius: 3px;
	color: white;
	text-decoration: none;
	
	&:hover {

		color: white;
		text-decoration: none;
	}

	&_blue {

		background-color: $primaryColor;

		&:hover {

			background-color: darken($primaryColor, 10%);
		}
	}

	&_cyan {

		background-color: $secondaryColor;

		&:hover {

			background-color: darken($secondaryColor, 10%);
		}
	}

	&_aqua {

		background-color: $secondaryColor;

		&:hover {

			background-color: darken($secondaryColor, 10%);
		}
	}

	&_red {

		background-color: $fourthColor;

		&:hover {

			background-color: darken($fourthColor, 10%);
		}
	}
}