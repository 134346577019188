.bid_profile {

	span {

		font-weight: 700;
	}

	a {

		color: $secondaryColor;

		&:hover {

			text-decoration: underline;
		}
	}
}