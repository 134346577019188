/**
 *
 * Include any useful helper classes here.
 *
 */

.noPad {

	padding-left: 0 !important;
	padding-right: 0 !important;
}