/**
 *
 * Add your colour variables here.
 *
 */


// PRIMARY COLOR PALLETTE


$bbBlue: rgb(38, 68, 149);
$bbCyan: rgb(37, 170, 222);
$bbAqua: rgb(45, 204, 211);
$bbLightGrey: lighten(rgb(208, 211, 212), 12% );
$bbGrey: rgb(162, 170, 173);
$bbDarkGrey: rgb(124, 135, 142);


$primaryColor: $bbBlue;
$secondaryColor: $bbAqua;
$tertiaryColor: $bbDarkGrey;
$fourthColor: $bbAqua;