form {

	&.contact_form {

		background-color: $bbLightGrey;
		padding: 20px;

		&[type="text"], &[type="email"], &[type="password"] {

			border: 0;
			border-radius: 0;
			background-color: white;
		}

		.bb_button {

			@media only screen and ($device-max) {

				display: block;
				width: 100%;
			}
		}
	}

	label {

		font-family: $primaryFont;
		font-weight: 400;
		color: $tertiaryColor;

		span {

			color: $fourthColor;
			font-weight: 700;
		}
	}

	input {

		&[type="text"], &[type="email"], &[type="password"], &[type="search"] {

			height: 42px;
			border-radius: 2px;
		}

		&[type="submit"] {

			border: 0;
			display: block;
			padding: 10px 20px;
		}

		&[type=range] {
		  -webkit-appearance: none;
		  width: 100%;
		  margin: 9px 0;
		}
		&[type=range]:focus {
		  outline: none;
		}
		&[type=range]::-webkit-slider-runnable-track {
		  width: 100%;
		  height: 2px;
		  cursor: pointer;
		  background: #ffffff;
		  border-radius: 1px;
		  
		}
		&[type=range]::-webkit-slider-thumb {
		  height: 16px;;
		  width: 16px;;
		  border-radius: 50px;
		  background: #f3553e;
		  cursor: pointer;
		  -webkit-appearance: none;
		  margin-top: -7px;
		}
		&[type=range]:focus::-webkit-slider-runnable-track {
		  background: #ffffff;
		}
		&[type=range]::-moz-range-track {
		  width: 100%;
		  height: 2px;
		  cursor: pointer;
		  background: #ffffff;
		}
		&[type=range]::-moz-range-thumb {
		  height: 16px;;
		  width: 16px;;
		  border-radius: 50px;
		  background: #f3553e;
		  cursor: pointer;
		}
		&[type=range]::-ms-track {
		  width: 100%;
		  height: 2px;
		  cursor: pointer;
		  background: transparent;
		  border-color: transparent;
		  color: transparent;
		}
		&[type=range]::-ms-fill-lower {
		  background: #f2f2f2;
		  border: 0px solid rgba(1, 1, 1, 0);
		  border-radius: 2px;
		  
		}
		&[type=range]::-ms-fill-upper {
		  background: #ffffff;
		  border: 0px solid rgba(1, 1, 1, 0);
		  border-radius: 2px;
		}
		&[type=range]::-ms-thumb {
		  height: 16px;;
		  width: 16px;;
		  border-radius: 50px;
		  background: #f3553e;
		  cursor: pointer;
		  height: 2px;
		}
	}

	.field_info {

		float: left;

		span {

			color: $fourthColor;
			font-weight: 700;
		}
	}
}