.footer_wrapper {

	background-color: $bbGrey;
	padding: 20px;

	.footer_navigation {

		ul {

			padding-left: 0;
		}

		>ul {

			li {
				font-family: $primaryFont;
				font-weight: 600;
				font-size: $baseFontSize * 0.70;
				text-transform: uppercase;
				margin-right: 20px;
				list-style: none;
				list-style-type: none;

				@media only screen and ($large-desktop) {

					font-size: $baseFontSize * 0.85;
				}

				&:last-child {

					margin-right: 0;
				}

				a {

					color: white;
					
				}

				ul {

					li {

						font-family: $primaryFont;
						font-weight: 400;
						font-size: $baseFontSize * 0.75;
						text-transform: uppercase;
						list-style: none;
						list-style-type: none;

						a {


						}
					}
				}
			}
		}
	}

	.footer_logos {

		img {

			display: block;

			&:first-child {

				margin-bottom: 10px;
			}
		}
	}
}

.sub_footer_wrapper {

	background-color: $bbDarkGrey;
	padding: 20px;

	.sub_footer_navigation {

		@media only screen and ($device-max) {

			text-align: center;
		}

		ul {

			@media only screen and ($device-max) {

				padding-left: 0;
				display: inline-block;
			}

			li {

				font-family: $primaryFont;
				font-weight: 400;
				font-size: $baseFontSize * 0.7;
				margin-right: 15px;

				a {

					color: white;
					
				}
			}

			margin-bottom: 10px;
		}
	}

	p {

		@media only screen and ($device-max) {

			text-align: center;
		}

		font-family: $primaryFont;
		font-weight: 400;
		font-size: $baseFontSize * 0.65;
		text-transform: uppercase;
		color: white;
		margin-bottom: 0;
	}

	.footer_contact {

		@media only screen and ($device-max) {

			text-align: center;
		}

		ul {

			margin-bottom: 0;

			@media only screen and ($device-max) {

				padding-left: 0;
				display: inline-block;

				li {

					display: inline-block;
				}
			}

			li {



				font-family: $primaryFont;
				font-weight: 400;
				font-size: $baseFontSize * 0.7;
				margin-right: 15px;
				color: $secondaryColor;
				list-style: none;
				list-style-type: none;
				text-align: right;

				a {

					color: $secondaryColor;
					
				}
			}
		}
	}

	.copyright {
      text-transform: none;
      padding:10px 10px 0 0;
      text-align: justify;

      @media only screen and ($device-max) {
        text-align: center;
        display: block;
        width: 100%;
        padding: 10px 0;
      }
	}
}