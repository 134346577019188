.listing_wrapper {

	margin-top: 30px;

	.list_item {

		padding: 20px;
		background-color: $bbLightGrey;
		margin-bottom: 20px;
		position: relative;

		.listing_member_shield {

			background-color: rgba(255, 255, 255, 0.6);
			position: absolute;
			width: 100%;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10px;
			border: 1px solid $bbLightGrey;

			p {

				@include absoluteCenter;
				font-family: $primaryFont;
				font-weight: 700;
				color: $tertiaryColor;
				font-size: $baseFontSize * 1.6;
				line-height: $baseFontSize * 2.4;
				text-align: center;
				width: 80%;

				a {
					font-weight: 400;
					color: $tertiaryColor;
				}
			}

		}

		.listing_thumbnail {

			@media only screen and ($device-max) {

				display: block;
				margin: 0 auto 20px;
				float: none !important;
			}

			margin-right: 20px;
			margin-bottom: 40px;
			max-width: 140px;
		}

		h2 {

			font-family: $primaryFont;
			font-weight: 700;
			color: $tertiaryColor;
			font-size: $baseFontSize * 1.6;
			margin-bottom: 10px;
			margin-top: 0;
		}

		h3 {

			font-family: $primaryFont;
			font-weight: 500;
			color: $primaryColor;
			font-size: $baseFontSize;
			text-transform: capitalize;

			span {

				text-transform: uppercase;
			}
		}

		span {

			font-family: $primaryFont;
			font-weight: 700;

		}

		.website {

			margin-bottom: 0;

			span {

				color: $secondaryColor;
			}

			a {

				color: $tertiaryColor;
				text-decoration: none;

				@media only screen and ($device-max) {

					word-break: break-all;
				}

				&:hover {

					text-decoration: underline;
				}
			}
		}

		.bid_type {

			margin-bottom: 0;

			span {

				color: $primaryColor;
			}

			text-transform: capitalize;
		}

		.bb_button {

			@media only screen and ($device-max) {

				display: block;
				width: 100%;
				float: none !important;
			}

			min-width: 150px;
			padding: 10px 15px; 
		}

		.publication_buttons {

			@media only screen and ($small-desktop) {

				float: right;
			}

			.bb_button {

				display: block;
				width: 100%;

				&:first-child {

					margin-bottom: 10px;
				}		

				@media only screen and ($small-desktop) {

					width: 160px;
					display: inline-block;

					&:last-child {

						margin-left: 20px;
					}
				}
			}
		}
	}
}