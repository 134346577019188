.subscribe_alert {

	width: 100%;
	display: block;
	padding: 15px;
	text-align: center;
	font-family: $primaryFont;
	font-size: $baseFontSize * 0.8;

	&-success {

		background-color: #d4edda;
		color: #205a2e;
	}

	&-error {

		background-color: #f8d7da;
		color: #622429;
	}

	p {
		margin-bottom: 0;
	}
}