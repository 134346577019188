.blog_post {

	.blog_post_meta {

		font-size: $baseFontSize;
		font-family: $primaryFont;
		color: $tertiaryColor;
		margin-top: 0 !important;

		span {

			font-size: $baseFontSize * 0.8;

			strong {

				font-weight: 600;
			}
		}
	}

	.blog_post_body {

		margin-top: 30px;
	
		.c-figure--full {

			img {

				width: 100%;
				display: block;
				margin: 0 auto;
			}
		}

		img {

			max-width: 100%;
			display: block;
		}

		p {

			a {

				color: $secondaryColor;
				font-weight: 700;
			}

			b, strong {

				color: $fourthColor;
				font-weight: 700;
			}
		}
	}
}