.ballot_search_wrapper {

	background-color: $primaryColor;
	padding: 20px;
	margin: 80px 0 50px;
	position: relative;

	@media only screen and ($small-desktop) {

		&:before {

			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 210px 50px 0;
			border-color: transparent transparent $primaryColor transparent;
			content: "";
			position: absolute;
			top: -50px;
			left: 0;
		}

		&:after {

			width: 0;
			height: 0;
			border-style: solid;
			border-width: 50px 210px 0 0px;
			border-color: $primaryColor transparent transparent transparent;
			content: "";
			position: absolute;
			bottom: -50px;
			left: 0;
		}
	}

	@media only screen and ($large-desktop) {

		&:before {

			border-width: 0 255px 50px 0;
		}

		&:after {

			border-width: 50px 255px 0 0px;
		}
	}

	h3 {

		color: white;
		font-family: $primaryFont;
		font-weight: 600;
		text-transform: uppercase;
		font-size: $baseFontSize * 1.4;
	}

	p {

		color: white !important;
		font-family: $primaryFont;
		font-weight: 400;
		font-size: $baseFontSize;

		span {

			font-weight: 700;
		}
	}

	form {

		label {

			color: white;
			font-family: $primaryFont;
			font-weight: 400;
			font-size: $baseFontSize;
			margin-top: 10px;
			margin-bottom: 10px;
		}

		input {

			&[type="text"], &[type="search"] {

				margin-bottom: 10px;
				border: 0;
				border-radius: 0;
			}

			&[type="submit"] {

				width: 100%;
				display: block;
				border: 0;
				border-radius: 0;
				padding: 10px 15px;
				margin-top: 10px;
			}
		}

		.max_wrapper {

			position: absolute;
			top: -10px;
			right: 0;
			font-family: $primaryFont;
			font-weight: 400;
			font-size: $baseFontSize * 0.85;
			color: white;
		}

		.form-group {

			position: relative;
		}

		select {

			margin-bottom: 10px;
			border: 0;
			border-radius: 0;
		}

		p {

			a {

				color: $fourthColor;
				font-weight: 700;

				&:hover {

					text-decoration: none;
					color: white;
				}
			}
		}
	}
}