.publication_body {

	img {
		max-width: 100%;
	}

	.bb_button {

		margin-top: 10px;
		width: 200px;
		display: block;
	}
}