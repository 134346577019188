.content_accordion {

	.card {

		margin-bottom: 10px;

		.card-header {

			position: relative;

			

			button {

				@include multiTransition;
				background-color: $secondaryColor;
				color: white;
				font-weight: 700;
				font-size: $baseFontSize * 1.4;
				font-family: $primaryFont;
				padding: 15px 60px 15px 30px;
				width: 100%;
				border-radius: 0;
				text-align: left;
				position: relative;
				border: none;

				&:hover {

					text-decoration: none;
					background-color: darken($secondaryColor, 10%);
				}

				&:after {

					@include verticalCenter;
					content: '';
					transform: rotate(90deg);
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 10px 10px 0 10px;
					border-color: #fff transparent transparent transparent;
					right: 20px;
					margin-top: -4px;

				}

				

				&[aria-expanded="false"] {

					background-color: $primaryColor;

					&:after {

						border-color: $secondaryColor transparent transparent transparent;
						transform: rotate(0deg);
						
					}

					&:hover {

						text-decoration: none;
						background-color: darken($primaryColor, 10%);
					}

				}
			}

			
		}

		.card-body {

			padding: 30px;
			background-color: $bbLightGrey;

			h4 {

				color: $tertiaryColor;
				text-transform: uppercase;
				font-family: $primaryFont;
				font-weight: 600;
				font-size: $baseFontSize * 1.2;
			}

			.course_date_row {

				padding-bottom: 10px;
				font-family: $primaryFont;
				font-weight: 400;
				color: $tertiaryColor;
				font-size: $baseFontSize;

				@media only screen and ($device-max) {

					border-bottom: 1px solid lighten($bbDarkGrey, 70%);
					margin-bottom: 10px;

					h4 {

						margin-top: 10px;
						margin-bottom: 0;
					}

					.bb_button {

						margin-top: 10px;
						margin-bottom: 10px;
					}
				}

				p {

					margin-bottom: 0;
				}

				.bb_button {

					display: block;
					width: 100%;
					padding: 10px 15px;
				}
			}
		}
	}
}