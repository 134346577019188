.team_member {

	height: 300px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;

	.member_trigger {

		position: absolute;
		width: 100%;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 2;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 43%, rgba(0,0,0,0) 51%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 43%,rgba(0,0,0,0) 51%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0) 43%,rgba(0,0,0,0) 51%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
	}

	.member_info {

		position: absolute;
		bottom: 20px;
		left: 20px;
		font-family: $primaryFont;
		color: white;
		font-size: $baseFontSize;
		z-index: 3;

		span {

			text-transform: uppercase;
			font-weight: 600;
		}
	}
}

.member_bio {

	padding: 20px;
	font-family: $primaryFont;
	color: $tertiaryColor;
	font-size: $baseFontSize;

	a {

		color: $fourthColor;
		font-weight: 700;
	}
}