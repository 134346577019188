.pagination_wrapper {

	font-family: $primaryFont;

	@media only screen and ($small-desktop) {

		float: right;
	}

	p {

		@media only screen and ($device-max) {

			display: block;
			width: 100%;
			text-align: center;
			margin: 0;

			&:first-of-type {

				margin-bottom: 10px;
			}
		}

		display: inline;
		margin-right: 20px;
	}

	.pagination_links {

		span {

			color: $secondaryColor;
			font-weight: 700;
		}

		a {

			color: $tertiaryColor;
			text-decoration: none;
		}

		.page_link {

			&:not(:last-child)	{

				margin-right: 10px;
			}
		}

		.current_page {

			margin-right: 10px;
		}

		.first_page_link {

			margin-right: 10px;
		}

		.prev_page_link {

			margin-right: 10px;
		}

		.last_page_link {

			margin-left: 10px;
		}

		.next_page_link {

			margin-left: 10px;
		}
	}
}